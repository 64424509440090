import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

const Users = () => import('@/views/users/Users')
const Botones = () => import('@/views/botones/Botones')
const FichaClinica = () => import('@/views/users/FichaClinicaUsuario')
const AdminUsers = () => import('@/views/users/AdminUsers')
const SuperAdminUsers = () => import('@/views/users/SuperAdminUsers')
const Alerts = () => import('@/views/alerts/Alerts')
const Alert = () => import('@/views/alerts/Alert')
const Cuadrantes = () => import('@/views/cuadrantes/Cuadrantes')
const GruposOperacionales = () => import('@/views/gruposOperacionales/GruposOperacionales')
const Publicidad = () => import('@/views/publicidad/Publicidad')
const PublicidadData = () => import('@/views/publicidad/PublicidadData')
const VideoCallRoom = () => import('@/views/videocall/VideoCallRoom')
const Configuration = () => import('@/views/configuration/Configuration')
const TuyaCamerasGrid = () => import('@/views/widgets/TuyaCamerasView/TuyaCamerasGrid')


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'users',
          name: 'Users',
          meta: { label: 'Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users,
            },
            {
              path: ':id',
              meta: { label: 'Ficha Paciente'},
              name: 'FichaPaciente',
              component: FichaClinica
            }
          ]
        },
        {
          path: 'admin-users',
          name: 'AdminUsers',
          meta: { label: 'Admin Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'AdminUsers',
              component: AdminUsers,
            }
          ]
        },
        {
          path: 'superadmin-users',
          name: 'SuperAdminUsers',
          meta: { label: 'Super Admin Users'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'SuperAdminUsers',
              component: SuperAdminUsers,
            }
          ]
        },
        {
          path: 'alertas',
          name: 'Alertas',
          meta: { label: 'Alertas'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Alertas',
              component: Alerts,
            },
            {
              path: ':id',
              meta: { label: 'Alerta'},
              name: 'Alerta',
              component: Alert,
            },
          ]
        },
        {
          path: 'cuadrantes',
          name: 'Cuadrantes',
          meta: { label: 'Cuadrantes'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Cuadrantes',
              component: Cuadrantes,
            },
          ]
        },
        {
          path: 'operational-groups',
          name: 'GruposOperacionales',
          meta: { label: 'GruposOperacionales'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'GruposOperacionales',
              component: GruposOperacionales,
            },
          ]
        },
        {
          path: 'buttons',
          name: 'Botones',
          meta: { label: 'Botones'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Botones',
              component: Botones,
            },
          ]
        },
        {
          path: 'monitors',
          name: 'TuyaCamerasGrid',
          meta: { label: 'TuyaCamerasGrid'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'TuyaCamerasGrid',
              component: TuyaCamerasGrid,
            },
          ]
        },
        {
          path: 'publicidad',
          name: 'Publicidad',
          meta: { label: 'Publicidad'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Publicidad',
              component: Publicidad,
            },
            {
              path: ':id',
              meta: { label: 'Datos Publicidad'},
              name: 'Publicidad',
              component: PublicidadData,
            },
          ]
        },
        {
          path: 'videocall',
          name: 'VideoCallRoom',
          meta: { label: 'VideoCallRoom'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'VideoCallRoom',
              component: VideoCallRoom,
            },
          ]
        },
        {
          path: 'configuration',
          name: 'Configuration',
          meta: { label: 'Configuration'},
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: '',
              name: 'Configuration',
              component: Configuration,
            }
          ]
        },
      ]
    },
    {
      path: '/',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    }
  ]
}

